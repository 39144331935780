.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.searchbox > div .el-input {
  flex: 1;
}
.searchboxItem {
  display: flex;
  align-items: center;
}
.searchboxItem .itemLabel {
  min-width: 6rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
